import React from 'react';

import Meta from '../components/meta';

import Contents from '../components/contents';

import { StaticImage } from "gatsby-plugin-image";

import ExtLink from '../components/extLink';



const Solution = (props) =>{

    return(
        <>

            <Meta
                title="ソリューション"
                description="アクロスソリューションズでは、流通業及び流通分野における、「電子商取引」を軸とした電子受発注システムや関連パッケージソフトウエアの企画・設計・開発・販売・サポート保守をワンストップで行っております。"
            />

            <Contents>

                <div className="c-sub-header">
                    <em className="c-sub-header--eng u-josef">
                    SOLUTION
                    </em>

                    <h1 className="c-sub-header--title">
                    ソリューション
                    </h1>

                    <p className="c-sub-header--sup">
                    アクロスソリューションズでは、流通業及び流通分野における、<br />
                    「電子商取引」を軸とした電子受発注システムや関連パッケージソフトウエアの<br />
                    企画・設計・開発・販売・サポート保守をワンストップで行っております。
                    </p>
                </div>

                <div className="p-solution">

                    <section className="p-solution-box">
                        <div className="p-solution-box-inner">

                            <div className="p-solution-box-cnt is-mos">
                                <div className="p-solution-box-cnt__logo">
                                    <ExtLink link="https://www.mosjapan.jp" title="モバイル受発注システム MOS" classTag="p-solution-box-cnt__logo--btn" target="true">
                                        <StaticImage
                                        src="../images/logos_mos_2x.png"
                                        alt="MOS"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-solution-box-cnt__title">
                                    <p className="p-solution-box-cnt__title--sup">
                                    商標登録済
                                    </p>
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    MOSはFAXや電話の代わりに、スマホ、タブレットPCで受発注業務がおこなえるモバイルウェブ受発注システムです。
                                    FAXや電話で受発注業務を行いたくない。環境を選ばずに受発注業務をおこないたい。定期的な受発注業務こそ楽にしたい。
                                    そんなご要望を柔軟に実現することができるパッケージシステムです。
                                    またMOSはモバイル環境に最適化されているため、FAXや電話に縛られず受発注業務をおこなうことができます。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.mosjapan.jp" title="モバイル受発注システム MOS" classTag="c-link--btn is-external" target="true">
                                    製品紹介ウェブサイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>
                            </div>

                            <div className="p-solution-box-image"></div>

                        </div>
                    </section>

                    <div className="p-solution-list">
                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">
                                <div className="p-solution-box-cnt__logo">
                                    <ExtLink link="https://www.moslite.jp/" title="ASP・Saas型 BtoB Web受発注 MOS Lite" classTag="p-solution-box-cnt__logo--btn" target="true">
                                        <StaticImage
                                        src="../images/logos_mos_lite_2x.png"
                                        alt="MOS Lite"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-solution-box-cnt__title">
                                    <p className="p-solution-box-cnt__title--sup">
                                    商標登録済
                                    </p>
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    MOSの使いやすさ、MOSの基本機能はそのままに初期費用0円、月額19,800円
                                    でご利用いただける新しいサービスです。お申し込み後すぐにご利用いただけます。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="https://www.moslite.jp/" title="ASP・Saas型 BtoB Web受発注 MOS Lite" classTag="c-link--btn is-external" target="true">
                                    製品紹介ウェブサイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>
                            </div>
                        </section>

                        <section className="p-solution-box is-double">
                            <div className="p-solution-box-cnt">
                                <div className="p-solution-box-cnt__logo">
                                    <ExtLink link="http://www.mox.jp/" title="クラウド発注サービス MOX" classTag="p-solution-box-cnt__logo--btn" target="true">
                                        <StaticImage
                                        src="../images/mox_logo_2x.png"
                                        alt="MOX"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-solution-box-cnt__title">
                                    <p className="p-solution-box-cnt__title--sup">
                                    商標登録済
                                    </p>
                                </div>

                                <div className="p-solution-box-cnt__desc">
                                    <p className="p-solution-box-cnt__desc--text">
                                    MOXは、スマホなどで仕入れ発注ができるクラウドサービスです。
                                    仕入先に受発注システムやEDIが導入されていなくとも、発注者が自分で商品情報を登録し
                                    ネットショップと同じ感覚で業務用の仕入発注ができます。
                                    </p>
                                </div>

                                <div className="c-link">
                                    <ExtLink link="http://www.mox.jp/" title="クラウド発注サービス MOX" classTag="c-link--btn is-external" target="true">
                                    製品紹介ウェブサイトへ<span className="c-link--cursor"></span>
                                    </ExtLink>
                                </div>
                            </div>
                        </section>
                    </div>

                </div>

            </Contents>

        </>
    )

}

export default Solution;


